<template>
  <section>
    <uspCartao
      titulo=""
      :fechavel="false"
      class="card-monitoramento"
      :class="{'tela-cheia' : telaCheia }"
    >
      <template #cabecalho>
        <section class="row align-items-center">
          <div class="col">
            Monitoramento de Sistemas Corporativos
          </div>
          <div class="acoes col col-lg-7 col-12">
            <uspDropdown
              variante="primario"
              alinhamento="esquerda"
              contorno
              :opcoes="opcoesEspera"
              texto="Sistemas"
              :fechavel="false"
            >
              <template #itens>
                <div class="px-4 seletor-sistemas">
                  <a
                    class="dropdown-item pl-0"
                    @click="toggleSelecionados"
                  >
                    {{ textoToggleSelecionados }}
                  </a>
                  <uspDropdownSeparador />
                  <input
                    v-model="sistemaBuscado"
                    class="form-control"
                    type="search"
                    placeholder="Filtrar por nome"
                    @keydown.enter.prevent="enter"
                  >
                  <uspDropdownSeparador />
                  <div
                    v-for="sistema in sistemasListados"
                    :key="sistema.ctxsis"
                    class="form-check"
                  >
                    <input
                      :id="sistema.ctxsis"
                      v-model="sistemasSelecionados"
                      class="form-check-input"
                      type="checkbox"
                      :value="sistema.nomsis"
                    >
                    <label
                      class="form-check-label dropdown-item"
                      :for="sistema.ctxsis"
                    >
                      {{ sistema.nomcnhsis }}
                    </label>
                  </div>
                </div>
              </template>
            </uspDropdown>
            <div class="btn-group mx-2">
              <uspDropdown
                variante="primario"
                contorno
                :opcoes="opcoesEspera"
                alinhamento="esquerda"
                :texto="esperaSelecionado.dados.texto"
                @clicar="setarIntervaloEspera"
              />
              <uspBotao
                icone="fas fa-sync"
                texto=""
                tamanho=""
                contorno
                :carregando="carregando"
                @clicar="recarregar"
              >
                <span class="sr-only">{{ carregando ? 'Carregando' : 'Recarregar' }}</span>
              </uspBotao>
              <uspDropdown
                variante="primario"
                contorno
                :opcoes="opcoesRefresh"
                :texto="textoSelecionadoRefresh"
                @clicar="setarIntervaloRefresh"
              />
            </div>

            <div class="btn-group mx-2">
              <uspBotao
                :icone="iconeExpande"
                texto="Tela cheia"
                contorno
                @clicar="expandirTela()"
              />
              <uspDropdown
                variante="primario"
                contorno
                texto="Mais"
              >
                <template #itens>
                  <a
                    class="dropdown-item"
                    @click="toggleExpandir"
                  >{{ textoToggleExpandir }}</a>
                </template>
              </uspDropdown>
            </div>
          </div>
        </section>
      </template>
      <template
        #corpo
      >
        <p v-if="!carregando && sistemasFiltrados.length===0">
          <em class="text-muted">Não há sistemas selecionados.</em>
        </p>
        <div class="px-2 row row-cols-1 row-cols-md-2 row-cols-xl-5">
          <div
            v-for="(sistema, idx) in sistemasFiltrados"
            :key="sistema.ctxsis"
            class="col"
          >
            <CardSistema
              :sistema="sistema"
              :carregando="carregando"
              :exibir="visiveis.includes(idx)"
              class="fill"
              @colapsar="colapsar(idx)"
            />
          </div>
        </div>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import MonitoramentoServico from "../dominio/MonitoramentoServico.js"
import CardSistema from "../components/CardSistema.vue"

const api = MonitoramentoServico.build({});

  export default {
    name: 'Monitoramento',
    components: {
      CardSistema
    },
    data() {
      return {
        visiveis: [],
        carregando: false,
        telaCheia: false,
        exibir: false,
        sistemas : [],
        sistemaBuscado: '',
        soma: 0,
        sistemasSelecionados: [],
        textoSelecionadoRefresh: 'Off',
        toggle: false,
        intervalo: '',
        erro: '',
        opcoesEspera: [
          {
            titulo: "1s - Agressivo",
            dados: {texto: 'Espera: 1s', tempo: 1000},
          },
          {
            titulo: "2s - Padrão",
            dados: {texto: 'Espera: 2s', tempo: 2000},
          },
          {
            titulo: "5s - Lento",
            dados: {texto: 'Espera: 5s', tempo: 5000},
          },
        ],
        esperaSelecionado: {
            dados: {texto: 'Espera: 2s', tempo: 2000},
          },
        opcoesRefresh: [
          {
            titulo: "Off",
            dados: {texto: 'Off', tempo: null},
          },
          {
            titulo: "5s",
            dados: {texto: '5s', tempo: 5000},
          },
          {
            titulo: "10s",
            dados: {texto: '10s', tempo: 10000},
          },
          {
            titulo: "30s",
            dados: {texto: '30s', tempo: 30000},
          },
        ],
      }
    },
    computed: {
      textoToggleExpandir() {
        return this.visiveis.length === 0 ? 'Expandir todos' : 'Colapsar todos'
      },
      iconeExpande() {
        return this.telaCheia ? 'fas fa-compress' : 'fas fa-expand'
      },
      sistemasFiltrados() {
        return this.sistemas?.filter((el) => this.sistemasSelecionados.indexOf(el.nomsis) != -1)
      },
      sistemasListados() {
        return this.sistemas?.filter((el) => el.nomcnhsis?.toLowerCase().includes(this.sistemaBuscado) )
      },
      textoToggleSelecionados() {
        return this.toggle ? 'Selecionar tudo' : 'Limpar selecionados'
      }
    },
    created() {
      this.carregando = true
        api.listarSistemas().then((resp) => {
          this.sistemas = resp
          this.selecionarTodos()
        }).catch((erro) => {
          this.erro = erro?.mensagem ?? erro;
        }).finally(() => this.carregando = false)
    },
    methods: {
      toggleExpandir() {
        this.visiveis.length === 0 ? (this.visiveis = [...Array(26).keys()]) : (this.visiveis = [])
      },
      colapsar(idx) {
        this.visiveis.includes(idx) ? this.visiveis.splice(this.visiveis.indexOf(idx), 1) : this.visiveis.push(idx)
      },
      enter() {
        if(this.sistemasListados.length > 1 ) { return }
        var nomsis = this.sistemasListados[0].nomsis
        this.sistemasSelecionados.includes(nomsis) ? this.sistemasSelecionados.splice(this.sistemasSelecionados.indexOf(nomsis), 1) : this.sistemasSelecionados.push(nomsis)
      },
      expandirTela() {
        this.telaCheia = !this.telaCheia
      },
      setarIntervaloEspera(dadosSelecionado) {
        this.opcoesRefresh.map((opcao) => {
          if(opcao.dados.tempo) {
            opcao.dados.tempo *= dadosSelecionado.tempo/this.esperaSelecionado.dados.tempo
            opcao.dados.texto = (opcao.dados.tempo/1000).toLocaleString('pt-BR', {maximumFractionDigits: 1}) + 's'
            opcao.titulo = opcao.dados.texto
          }
        })
        this.esperaSelecionado = this.opcoesEspera.find((el) => el.dados.tempo === dadosSelecionado.tempo)
        this.limparIntervalo()
      },
      setarIntervaloRefresh(dados) {
        if(dados.tempo) { this.recarregar() }
        clearInterval(this.intervalo)
        this.textoSelecionadoRefresh = dados.texto
        this.intervalo = dados.tempo ? setInterval(() => {
            this.recarregar()
          }, dados.tempo)
          : null;
      },
      recarregar() {
        this.carregando = true
        api.listarSistemas(this.esperaSelecionado.dados.tempo)
          .then((resp) => {
            this.sistemas = resp
          }).catch((erro) => {
            this.erro = erro?.mensagem ?? erro;
          }).finally(() => this.carregando = false)
      },
      toggleSelecionados() {
        this.toggle = !this.toggle
        if(this.toggle) { this.sistemasSelecionados = [] } else { this.selecionarTodos() }
      },
      limparIntervalo() {
        clearInterval(this.intervalo)
        this.textoSelecionadoRefresh = 'Off'
      },
      selecionarTodos() {
        this.sistemasSelecionados = this.sistemas.map((e) => e.nomsis)
      },
    }
  }
</script>

<style lang="scss">
.card-monitoramento {
  .card-header h5 {
    width: 100%;
  }
  /* Botões */
  .btn-group > .btn:not(:first-child),
  .btn-group > .dropdown:not(:first-child) > .dropdown-toggle {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .dropdown:not(:last-child) > .dropdown-toggle {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .btn-group * {
    margin: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "../../componentes/estilos/temas";

.card-monitoramento {
  &.tela-cheia {
    position: absolute;
    left: 0;
    z-index: 1030;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
  }
  .acoes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .dropdown-menu {
      .seletor-sistemas {
        max-height: 60vh;
        min-width: 20rem;
      }
    }
  }
  
  .col {
    padding: 0 8px;
    .fill {
      max-height: 100%;
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .tela-cheia {
      top: map-get($tema, "usp-barra-superior-altura-total");
    }
  }
}

.fill {
  max-height: 100%;
}

</style>