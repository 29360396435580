<template>
  <div>
    <uspAlerta
      :variante="variante(sistema.situacao)"
      :class="{ 'fill' : exibir }"
    >
      <a @click="colapsar">
        <div class="d-flex justify-content-between align-items-center">
          <h5>{{ sistema.nomcnhsis || sistema.nomsis }} </h5>
          <em :class="iconeColapsar" />
        </div>
      </a>
      <div
        v-for="vm in sistema.vms"
        v-show="exibir"
        :key="vm.endipmaqvir"
      >
        <uspBotao
          texto=""
          :variante="variante(vm.situacao)"
          tamanho="bc"
          class="mb-2 d-flex justify-content-between"
        >
          {{ vm.nommaqvir }}
          <i :class="iconeVm(vm)" />
        </uspBotao>
      </div>
    </uspAlerta>
  </div>
</template>

<script>
  export default {
    name: 'CardSistema',
    props: {
      sistema: {
        type: Object,
        required: true
      },
      carregando: {
        type: Boolean,
        required: true
      },
      exibir: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      iconeColapsar() {
        return this.exibir ? "fas fa-chevron-up" : "fas fa-chevron-down";
      },
    },
    methods: {
      variante(situacao) {
        switch (situacao) {
          case 'OK':
            return 'sucesso';
          case 'WARNING':
            return 'aviso';
          case 'FATAL':
            return 'perigo';
          default:
            return
        }
      },
      iconeVm(vm) {
        if(this.carregando) { return 'spinner-grow spinner-grow-sm m-0' }
        else {
          switch (vm.situacao) {
            case 'OK':
              return 'fas fa-signal m-0'
            case 'FATAL':
              return 'fas fa-skull m-0'
            default:
              return
          }
        }
      },
      colapsar() {
        this.$emit('colapsar')
      }
    }
  }
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.fill {
  height: calc(100% - 8px);
}
</style>